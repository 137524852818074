import React from 'react';
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";

const PortfolioDetailsPage = () => {

    return (
        <fragment>
            <div className={'main-wrapper has-side-header'}>
                <HeaderTwo />
                <SideHeader mobile={false} />
                <div className="section-wrap section section-padding bg-grey" >
                    <div className="container"><div className="row">
                        <div className="col">

                            <div className="portfolio-details-content">
                                <p className="category">HTML, JS, CSS</p>
                                <h1 className="title">Coulombs Law Demo - 2021</h1>
                            </div></div>
                    </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <h2>Brief</h2>
                                <p>A demo showing electrostatic forces using Coulombs Law.</p></div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <iframe title="coulombs" src="https://testing.tomloader.uk/coulomb/electrostaticsdemo.html"></iframe>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <h2>What is it?</h2>
                                    <p>
                                        This is a demo of <a href="https://en.wikipedia.org/wiki/Coulomb%27s_law" target=" _blank" rel="noopener noreferrer"> Coulomb's Law</a> created by <a href="https://www.linkedin.com/mwlite/in/olly-eatough-889814214" target="_blank" rel="noopener noreferrer">Oliver Eatough</a> and I, in a series of small projects, to show the relationship and resultant forces of charged points.
                                    </p>


                                </div>
                            </div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <img src={require('../../../assets/images/portfolio/coulombs/code.jpg')} alt="Code for Demo"/>
                            </div>
                        </div>
                        <br />
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <div className="project-share"><span>Share:</span><a href="/"><i className="fa fa-facebook"></i></a><a href="/"><i className="fa fa-twitter"></i></a><a href="/"><i className="fa fa-pinterest-p"></i></a><a href="/"><i className="fa fa-google-plus"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </fragment>
    );
};

export default PortfolioDetailsPage;