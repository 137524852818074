import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Portfolio from "../../../components/portfolio";
import Loading from "../../../components/loading";



const PortfolioGridThreeColumn = ({portfolios, loading, fullWidth, allItems}) => {

    return (
        
		<Fragment>
            <div className="section-wrap section section-padding bg-grey">
                <Container fluid={!!fullWidth} className={fullWidth ? 'p-0' : null}>
                    <Row className={'row-7 portfolio-column-three'}>
                        {portfolios.map(portfolio => (
                            <Col key={portfolio.id} md={6} lg={4} className={'mb-15'}>
                                <Portfolio
									id={portfolio.id}
                                    title={portfolio.title}
                                    thumb={portfolio.thumb}
                                    category={portfolio.category}
                                    link={portfolio.link}
                                    variant={'column'}
                                />
                            </Col>
                        ))}
                    </Row>
					
                </Container>
				
            </div>

            {loading ? <Loading/> : null}
        </Fragment>

    );
};

export default PortfolioGridThreeColumn;