import React from 'react';
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";

const PortfolioDetailsPage = () => {

    return (
        <fragment>
            <div className={'main-wrapper has-side-header'}>
                <HeaderTwo />
                <SideHeader mobile={false} />
                <div className="section-wrap section section-padding bg-grey" >
                    <div className="container"><div className="row">
                        <div className="col">

                            <div className="portfolio-details-content">
                                <p className="category">HTML, JS, CSS, PHP, MYSQL</p>
                                <h1 className="title">Sierra Restaurant Reservations - 2018</h1>
                            </div></div>
                    </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <h2>Brief</h2>
                                <p>A Web Application allowing reservations to be made with registration, login, email and accounts.</p></div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <iframe title="sierra" src="http://old.tomloader.uk/reservations"></iframe>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <h2>What is it?</h2>
                                    <p>This is a project made for my Computing A-Level. This website consists of a landing page including Facebook Comments API, Google Maps and Google Analytics, Reservations, login, registration, password resetting, staff page and user account.</p>
                                    <p>The user will select the number of people in their party, a date of their choosing and whether they will be dining for lunch or dinner. They are then shown a list of available time slots for those specific inputs. From there they can login or check out as a guest where they can add special requests like for a highchair or allergy information. Once the reservation has been placed an email confirmation will be sent detailing their reservation.</p>
                                    <p>The user can then view their current and past reservations in their account where they can cancel bookings if it is not within the next x time frame. Within their account users can also edit their information and change their password.</p>
                                    <p>Users can create an account using their name, email address and password and will be sent an email to verify the account. The user will be automatically logged in on account creation.</p>
                                    <p>Staff can view a timetable of a selected day on their own protected page where they can book off holiday days, retrieve customer information (phone numbers) by name and search for specific bookings by name and email.</p>
                                    <p>Backend is written in PHP & DB is MySQL</p>
                                    <p className="text-muted">Note: SMTP is not set up in demo</p>
                                </div>
                            </div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col-lg-4 col-md-6">
                                <img src={require('../../../assets/images/portfolio/sierra/index.jpg')} alt="Landing page" />
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <img src={require('../../../assets/images/portfolio/sierra/employee.jpg')} alt="Employee panel"/>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <img src={require('../../../assets/images/portfolio/sierra/account.jpg')} alt="Account"/>
                            </div>

                        </div>
                        <br />
                        <div className="row portfolio-details-content">
                            <div className="col-lg-4 col-md-6">
                                <img src={require('../../../assets/images/portfolio/sierra/login.jpg')} alt="Login"/>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <img src={require('../../../assets/images/portfolio/sierra/register.jpg')} alt="Register"/>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <img src={require('../../../assets/images/portfolio/sierra/forgot.jpg')} alt="Forgot Password"/>
                            </div>
                        </div>
                        <br />
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <div className="project-share"><span>Share:</span><a href="/"><i className="fa fa-facebook"></i></a><a href="/"><i className="fa fa-twitter"></i></a><a href="/"><i className="fa fa-pinterest-p"></i></a><a href="/"><i className="fa fa-google-plus"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </fragment>
    );
};

export default PortfolioDetailsPage;