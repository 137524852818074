import React from 'react';
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";

const PortfolioDetailsPage = () => {

    return (
        <fragment>
            <div className={'main-wrapper has-side-header'}>
                <HeaderTwo />
                <SideHeader mobile={false} />
                <div className="section-wrap section section-padding bg-grey" >
                    <div className="container"><div className="row">
                        <div className="col">

                            <div className="portfolio-details-content">
                                <p className="category">C++, OpenGL</p>
                                <h1 className="title">Perlin Noise OpenGL Test - 2020</h1>
                            </div></div>
                    </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <h2>Brief</h2>
                                <p>An implementation of simplex noise utilising WebGl2.</p></div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="row portfolio-details-content">
                                <div className="col-lg-6">
                                    <img src={require('../../../assets/images/portfolio/opengl/scene_light.png')} alt="Light Scene"/>
                                </div>
                                <div className="col-lg-6">
                                    <img src={require('../../../assets/images/portfolio/opengl/scene_dark.png')} alt="Dark Scene"/>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <h2>What is it?</h2>
                                    <p>This is an implementation of <a href=" https://en.wikipedia.org/wiki/Perlin_noise" target="_blank" rel="noopener noreferrer"> Perlin Noise </a> created by <a href="https://www.linkedin.com/mwlite/in/olly-eatough-889814214" target="_blank" rel="noopener noreferrer">Oliver Eatough</a> and I, using C++ and OpenGL using GLFW OpenGl library.</p>

                                    <p>In this we are using Perlin noise to generate voxels in chunks similar to Minecraft. These chunks of blocks are then run through a mesh creating algorithm to create vertex arrays for the visible parts of the chunk.</p>
                                    <p>We implemented moving cameras with gravity and no-clip to be able to explore the terrain from different angles. To further this we created collision detection to be able to collide with the objects to explore our terrain.</p>
                                    <p>We also created a fragment shader to light the terrain based on the position of a moving `sun`.</p>
                                    <p>This project was to experiment coding 3d games and engines with raw C++ without using external tools such as Unity or Unreal Engine.</p>


                                </div>
                            </div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/opengl/mesh.png')} alt="Mesh Close Up"/>
                            </div>
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/opengl/collision.png')} alt="Collided Player"/>
                            </div>
                        </div>
                        <br />
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <div className="project-share"><span>Share:</span><a href="/"><i className="fa fa-facebook"></i></a><a href="/"><i className="fa fa-twitter"></i></a><a href="/"><i className="fa fa-pinterest-p"></i></a><a href="/"><i className="fa fa-google-plus"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </fragment>
    );
};

export default PortfolioDetailsPage;