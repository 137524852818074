import React from 'react';
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";

const PortfolioDetailsPage = () => {

    return (
        <fragment>
            <div className={'main-wrapper has-side-header'}>
                <HeaderTwo />
                <SideHeader mobile={false} />
                <div className="section-wrap section section-padding bg-grey" >
                    <div className="container"><div className="row">
                        <div className="col">

                            <div className="portfolio-details-content">
                                <p className="category">HTML, CSS, JS, PHP, MYSQL</p>
                                <h1 className="title">Loaders Garage Invoicing - 2020</h1>
                            </div></div>
                    </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <h2>Brief</h2>
                                <p>A web-application custom built using Bootsrap 4 framework, PHP backend and MYSQL integration.</p></div>
                        </div>

                        <div className="row portfolio-details-content">
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/loaders/login.png')} alt="Login"/>
                            </div>
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/loaders/invoicing.png')} alt="Invoicing"/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <br />
                                    <h2>What is it?</h2>
                                    <p>The customer inputs details of a customer, their vehicle, work carried out by the garage as a table, labour time and noted for the customer. This information is then stored to an SQL Database and subsequently sent to an FPDF implimentation to create a formatted PDF in a printable format with two copies to be filed and given to the customer.</p>
                                    <p>This project required a login, invoicing page, admin tools for sales insights and configurable options for VAT Rate, Labour Rate and to add new users. These all increase productivity, replacing the original paper based system that required manual addition and totalling of parts and generating RRPs for items by automating these steps. Storing the information also allows for quick searching of previous invoices which was previously not possible with physical filing. Another benefit is the ability to check earlings on a month by month basis shown visibly on a scatter graph and pie chart showing estimated revenue and a break down of earnings.</p>
                                </div>
                            </div></div>
                        <div className="row portfolio-details-content">
                            <div className="col-lg-4 col-md-6 col-12">
                                <img src={require('../../../assets/images/portfolio/loaders/insights.png')} alt="Insights"/>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <img src={require('../../../assets/images/portfolio/loaders/invoice.png')} alt="Invoice Example"/>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <img src={require('../../../assets/images/portfolio/loaders/settings.png')} alt="Settings"/>
                            </div>

                        </div>
                        <br />
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <div className="project-share"><span>Share:</span><a href="/"><i className="fa fa-facebook"></i></a><a href="/"><i className="fa fa-twitter"></i></a><a href="/"><i className="fa fa-pinterest-p"></i></a><a href="/"><i className="fa fa-google-plus"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </fragment>
    );
};

export default PortfolioDetailsPage;