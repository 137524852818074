import React from 'react';
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";

const PortfolioDetailsPage = () => {

    return (
        <fragment>
            <div className={'main-wrapper has-side-header'}>
                <HeaderTwo />
                <SideHeader mobile={false} />
                <div className="section-wrap section section-padding bg-grey" >
                    <div className="container"><div className="row">
                        <div className="col">

                            <div className="portfolio-details-content">
                                <p className="category">PIXIJS, JS, Node, Socket.io</p>
                                <h1 className="title">Tanks Game PIXIJS - 2021</h1>
                            </div></div>
                    </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <h2>Brief</h2>
                                <p>A Tank game using PIXIJS, JS Socket.io and Node backend.</p></div>
                        </div>
                        
                            <div className="row portfolio-details-content">
                                <div className="col-lg-6">
                                    <img src={require('../../../assets/images/portfolio/tanks/main.png')} alt="Main Menu"/>
                                </div>
                                <div className="col-lg-6">
                                    <img src={require('../../../assets/images/portfolio/tanks/game.png')} alt="Game Scene"/>
                                </div>
                            </div>
                        
                        <br />
                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <h2>What is it?</h2>
                                    <p>This is a game created in JS using PIXIJS created by <a href="https://www.linkedin.com/mwlite/in/olly-eatough-889814214" target="_blank" rel="noopener noreferrer">Oliver Eatough</a> and I, I focused on the front-end, finding and creating assets and managing loaders and sprites within pixi. Players can pick colours of their tanks base and turret, taking inspiration from <a href="https://www.tanktrouble.com/" target="_blank" rel="noopener noreferrer">Tank Trouble</a>, and entering a handle. The user then joins a lobby and waits for a second player.</p>

                                    <p>The map is created dynamically using <a href=" https://en.wikipedia.org/wiki/Maze_generation_algorithm" target="_blank" rel="noopener noreferrer">Prims algorithm</a>, which is then parse to create visual lines. These same lines are also used in the collision system.</p>


                                    <p>The player can shoot bullets the will bounce off of the walls until it hits you or another player. When someone has died the opponent will gain a point and the scene resets. Once a player has reached x points the game ends.</p>

                                    <p>Movement of players is updated every frame and sent to a Node backend using Socket.io broadcasts. The collisions player locations and maze are all handled and generated on the backend with node.</p>

                                </div>
                            </div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <img src={require('../../../assets/images/portfolio/tanks/lobby.png')} alt="lobby Screen"/>
                            </div>
                        </div>
                        <br />
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <div className="project-share"><span>Share:</span><a href="/"><i className="fa fa-facebook"></i></a><a href="/"><i className="fa fa-twitter"></i></a><a href="/"><i className="fa fa-pinterest-p"></i></a><a href="/"><i className="fa fa-google-plus"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </fragment>
    );
};

export default PortfolioDetailsPage;