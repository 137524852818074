import React from 'react';
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";

const PortfolioDetailsPage = () => {

    return (
        <fragment>
            <div className={'main-wrapper has-side-header'}>
                <HeaderTwo />
                <SideHeader mobile={false} />
                <div className="section-wrap section section-padding bg-grey" >
                    <div className="container"><div className="row">
                        <div className="col">

                            <div className="portfolio-details-content">
                                <p className="category">WEBGL2, HTML, JS, CSS</p>
                                <h1 className="title">Simplex Noise WebGL - 2021</h1>
                            </div></div>
                    </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <h2>Brief</h2>
                                <p>An implementation of simplex noise utilising WebGl2.</p></div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <iframe title="simplex" src="https://testing.tomloader.uk/simplex/simplexnoise.html"></iframe>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <h2>What is it?</h2>
                                    <p>
                                        This is an implementation of <a href="http://staffwww.itn.liu.se/~stegu/simplexnoise/simplexnoise.pdf" target="_blank" rel="noopener noreferrer"> Simplex Noise</a> created by <a href="https://www.linkedin.com/mwlite/in/olly-eatough-889814214" target="_blank" rel="noopener noreferrer">Oliver Eatough</a> and I, in a series of small projects, utilising WebGL2 to create a fragment shader in GLSL and modify uniforms with JS. Simplex Noise has many implications in computing in applications such as procedural terrain generation and texture generation used in games and modelling.
</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/simplex/code.jpg')} alt="Main Code"/>
                            </div>
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/simplex/fragment.jpg')} alt="Fragment Shader"/>
                            </div>
                        </div>
                        <br />
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <div className="project-share"><span>Share:</span><a href="/"><i className="fa fa-facebook"></i></a><a href="/"><i className="fa fa-twitter"></i></a><a href="/"><i className="fa fa-pinterest-p"></i></a><a href="/"><i className="fa fa-google-plus"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </fragment>
    );
};

export default PortfolioDetailsPage;