import React from 'react';
import SideHeader from "../../../components/SideHeader";
import Header from "../../../components/header/HeaderTwo";
import PageContainer from "../../../container/CustomPage";

const PortfolioDetailsPage = () => {

    return (
        <div className={'main-wrapper has-side-header'}>
                <Header />
                <SideHeader mobile={false} />
            <div className="section-wrap section section-padding bg-grey" >
                    <div className="container"><div className="row">
                        <div className="col">

                            <div className="portfolio-details-content">
                                <p className="category">HTML, JS, CSS</p>
                                <h1 className="title">Conway's Game of Life - 2021</h1>
                            </div></div>
                    </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <h2>Brief</h2>
                                <p>An implementation of Conway's Game in JS.</p></div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <iframe title="conways" src="https://testing.tomloader.uk/conways/gameoflife.html"></iframe>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <h2>What is it?</h2>
                                    <p>
                                        This is an implementation of <a href="https://en.wikipedia.org/wiki/Conway%27s_Game_of_Life" target="_blank" rel="noopener noreferrer"> Conway's Game of Life</a> created by <a href="https://www.linkedin.com/mwlite/in/olly-eatough-889814214" target="_blank" rel="noopener noreferrer">Oliver Eatough</a> and I, in a series of small projects, utilising JS to recreate the game for browser. The game is based around 4 basic rules resulting in complex behaviours.
                                    </p>

                                    <ul>
                                        <li>Any live cell with fewer than two live neighbours dies, as if by underpopulation.</li>
                                        <li>Any live cell with two or three live neighbours lives on to the next generation.</li>
                                        <li>Any live cell with more than three live neighbours dies, as if by overpopulation.</li>
                                        <li>Any dead cell with exactly three live neighbours becomes a live cell, as if by reproduction.</li>
                                    </ul>
                                    <p>
                                        This implementation unfortunately has bugs when in edge cells.
                                    </p>


                                </div>
                            </div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <img src={require('../../../assets/images/portfolio/conways/code.jpg')} alt="Conways Code"/>
                            </div>
                        </div>
                        <br />
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <div className="project-share"><span>Share:</span><a href="/"><i className="fa fa-facebook"></i></a><a href="/"><i className="fa fa-twitter"></i></a><a href="/"><i className="fa fa-pinterest-p"></i></a><a href="/"><i className="fa fa-google-plus"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
    );
};

export default PortfolioDetailsPage;