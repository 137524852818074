import React from 'react';
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";

const PortfolioDetailsPage = () => {

    return (
        <fragment>
            <div className={'main-wrapper has-side-header'}>
                <HeaderTwo />
                <SideHeader mobile={false} />
                <div className="section-wrap section section-padding bg-grey" >
                    <div className="container"><div className="row">
                        <div className="col">

                            <div className="portfolio-details-content">
                                <p className="category">HTML, CSS, JS, PHP, MYSQL</p>
                                <h1 className="title">Loaders Garage Front-end & UX Overhaul - 2022</h1>
                            </div></div>
                    </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <h2>Brief</h2>
                                <p>A front-end and UX overhaul to an existing system created by me.</p></div>
                        </div>

                        <div className="row portfolio-details-content">
                            <div className="col-lg-6">
                                    <img src={require('../../../assets/images/portfolio/loaders_updated/index_original.jpg')} alt="Original" title="Original"/>
                                
                            </div>
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/loaders_updated/index.jpg')} alt="Updated" title="Updated"/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <br />
                                    <h2>What is it?</h2>
                                    <p>This is a well needed front-end and UX overhaul for my invoicing system made for Loaders Garage LTD. The original project can be found here <a href="https://tomloader.uk/portfolio/loaders"><i>Loaders</i></a>. The goal was to fix any issues with the existing system and provide extra needed functionality. This consisted of updating the design to a cleaner and more modern aesthetic and adding typeahead functionality, automatic oil rrp calculations, parts editing within table rows and adding modals. </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <h2>Efficiency and Quality of life improvements</h2>
                                    <p>In the original system you had to manually type parts descriptions and vehicle makes. This is slow and repetative so i have implemented <a href="https://twitter.github.io/typeahead.js/"><i>Typeahead.js</i></a>. This auto suggests inputs from a defined set of inputs increasing efficiency.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <img src={require('../../../assets/images/portfolio/loaders_updated/typeahead1.png')} alt="Typeahead Example" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <br />
                                    <p>Before when printing invoices it would open in a new tab, i have since created a modal with an iframe to post to preventing time wasted closing and managing multiple tabs.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row portfolio-details-content">
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/loaders_updated/loadModal_blurred.jpg')} alt="Load Modal" />
                            </div>
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/loaders_updated/printModal.jpg')} alt="Print Modal" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <br />
                                    <h2>Insights Page.</h2>
                                    <p>Insights page has been completely reworked to display totals and estimated revenue clearer.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row portfolio-details-content">
                            <div className="col">
                                <img src={require('../../../assets/images/portfolio/loaders_updated/insights_blurred.jpg')} alt="Insights" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <br />
                                    <h2>Miscellaneous Updates</h2>
                                    <p>All other pages have been updated to use the new design and printModals have been implemented for printing phonebook. Settings page has been cleaned by storing different functionality in seperate tabs within the page.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row portfolio-details-content">
                            <div className="col">
                                <img src={require('../../../assets/images/portfolio/loaders_updated/settings_blurred.jpg')} alt="Settings" title="Settings"/>
                            </div>
                        </div>
                        <br/>

                        <div className="row portfolio-details-content">
                            <div className="col-md-6 col-12">
                                <img src={require('../../../assets/images/portfolio/loaders_updated/phoneBook_blurred.jpg')} alt="Phonebook" title="Phonebook"/>
                            </div>
                            <div className="col-md-6 col-12">
                                 <img src={require('../../../assets/images/portfolio/loaders_updated/printPhone_blurred.jpg')} alt="Print Phonebook" title="Print Phonebook"/>
                            </div>

                        </div>
                        <br />
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <div className="project-share"><span>Share:</span><a href="/"><i className="fa fa-facebook"></i></a><a href="/"><i className="fa fa-twitter"></i></a><a href="/"><i className="fa fa-pinterest-p"></i></a><a href="/"><i className="fa fa-google-plus"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </fragment>
    );
};

export default PortfolioDetailsPage;