import React from 'react';
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";

const PortfolioDetailsPage = () => {

    return (
        <fragment>
            <div className={'main-wrapper has-side-header'}>
                <HeaderTwo />
                <SideHeader mobile={false} />
                <div className="section-wrap section section-padding bg-grey" >
                    <div className="container"><div className="row">
                        <div className="col">

                            <div className="portfolio-details-content">
                                <p className="category">HTML, CSS</p>
                                <h1 className="title">Wilson Zoo (Uni Assignment) - 2021</h1>
                            </div></div>
                    </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <h2>Brief</h2>
                                <p>A Static web-page using raw HTML and CSS for a university assignment.</p></div>
                        </div>

                        <div className="row portfolio-details-content">
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/wilson/main.jpg')} alt="Main page"/>
                            </div>
                            <div className="col-lg-6">
                                <img src={require('../../../assets/images/portfolio/wilson/about.jpg')} alt="About page"/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <br />
                                    <h2>What is it?</h2>
                                    <p>
                                        This is a simple static website made for a university assignment. The goal was to produce a website without external libraries of frameworks in pure HTML and CSS. The assignment had to meet marking criteria by showing examples off a checklist. These includes what needed to be written to the website and requirements like:
                                    </p>
                                    <ul>
                                        <li>Meta tags for charset, description, viewport, theme colours</li>
                                        <li>Link tags for style, manifest and icons</li>
                                        <li>Main navigation using an unordered list of links to the four main pages</li>
                                        <li>2 different semantic tags for grouping (e.g. header, article, footer, nav)</li>
                                        <li>Only contain the 6 pages - Home Page, About the Zoo, The Animals, Kids Page, Bookings / Donations, Prototype holding page.</li>
                                    </ul>
                                    <br />
                                    <p>
                                        There were also technical requirements such as including functioning service workers and functional page anchors other examples are:
                                    </p>
                                    <ul>
                                        <li>Picture tag with a suitable, modern (e.g. AVIF, WEBP) optimised image format</li>
                                        <li>Picture tag with art directed optimised images for mobile and desktop</li>
                                        <li>Picture tag fallback for older browsers</li>
                                        <li>Multi-resolution images for different screen resolutions (NOT SVG)</li>
                                        <li>SVG image logo with link to the home page</li>
                                        <li>HTML Comments to show the source of the images</li>

                                    </ul>
                                    <br />
                                    <p>
                                        Other requirements include CSS, Lighthouse tests including PWA and being mobile first.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row portfolio-details-content">
                            <div className="col-md-6 col-12">
                                <img src={require('../../../assets/images/portfolio/wilson/animals.jpg')} alt="Animals"/>
                            </div>
                            <div className="col-md-6 col-12">
                                <img src={require('../../../assets/images/portfolio/wilson/kids.jpg')} alt="Kids"/>
                            </div>
                        </div>

                        <div className="row portfolio-details-content">
                            <div className="col">
                                <div className="project-share"><span>Share:</span><a href="/"><i className="fa fa-facebook"></i></a><a href="/"><i className="fa fa-twitter"></i></a><a href="/"><i className="fa fa-pinterest-p"></i></a><a href="/"><i className="fa fa-google-plus"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </fragment>
    );
};

export default PortfolioDetailsPage;