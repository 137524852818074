import React from 'react';
import Header from "../../components/header/HeaderTwo";
import SideHeader from "../../components/SideHeader";
import ContentAboutPage from "../../templates/AboutPage";
import FooterThree from "../../components/footer/FooterThree";

const AboutPage = () => {
    return (
        <div className={`main-wrapper has-side-header`}>
            <Header/>
            <SideHeader mobile={false}/>
            <ContentAboutPage/>
        </div>
    );
};

export default AboutPage;