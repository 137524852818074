import React from 'react';
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";

const PortfolioDetailsPage = () => {

    return (
        <fragment>
            <div className={'main-wrapper has-side-header'}>
                <HeaderTwo />
                <SideHeader mobile={false} />
                <div className="section-wrap section section-padding bg-grey" >
                    <div className="container"><div className="row">
                        <div className="col">

                            <div className="portfolio-details-content">
                                <p className="category">HTML, CSS</p>
                                <h1 className="title">Wilson Zoo 2 - Monkey Bar (Uni Assignment) - 2021</h1>
                            </div></div>
                    </div>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <h2>Brief</h2>
                                <p>A continuation of Wilson Zoo utilising JS to create a basic ordering and basket system.</p></div>
                        </div>

                        

                        <div className="row">
                            <div className="col">
                                <div className="portfolio-details-content">
                                    <h2>What is it?</h2>
                                    <p>
                                        This is a continuation of the Wilson Zoo assignment where in we were tasked to create a basic ordering and basket system. The user will select whether they would like a smoothie or a milkshake and options specific to that drink are shown such as base or toppings. They will then select their ingredients and add to basket. This is then added to the basket and displayed to the user where they can add more until they wish to place their order.
                                    </p>
                                    <p>
                                        Users can also save their favourite drink by selecting type, the ingredients and base/toppings then selecting save favourite will store this to local storage.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row portfolio-details-content">
                            <div className="col">
                                <img src={require('../../../assets/images/portfolio/monkey/index.jpg')} alt="Ordering System"/>
                            </div>
                        </div>
                        <br/>
                        <div className="row portfolio-details-content">
                            <div className="col">
                                <div className="project-share"><span>Share:</span><a href="/"><i className="fa fa-facebook"></i></a><a href="/"><i className="fa fa-twitter"></i></a><a href="/"><i className="fa fa-pinterest-p"></i></a><a href="/"><i className="fa fa-google-plus"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </fragment>
    );
};

export default PortfolioDetailsPage;