import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

/*
* @ Component Imported
* */
import AboutPage from "./pages/about";
import ErrorPage from "./pages/404Error";

import PortfolioGridThreeColumnPage from "./pages/portfolio/grid/three-column";
import loaders from "./pages/portfolio/loaders";
import wilson from "./pages/portfolio/wilson";
import monkey from "./pages/portfolio/monkey";
import simplex from "./pages/portfolio/simplex";
import conway from "./pages/portfolio/conway";
import coulombs from "./pages/portfolio/coulombs";
import sierra from "./pages/portfolio/sierra";
import opengl from "./pages/portfolio/opengl";
import tanks from "./pages/portfolio/tanks";
import loaders2 from "./pages/portfolio/loaders_updated";


const App = () => {
    return (
        <Fragment>
            <Router>
                <Switch>

                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/loaders"}`}
                        component={loaders} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/wilson"}`}
                        component={wilson} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/simplex"}`}
                        component={simplex} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/monkey-bar"}`}
                        component={monkey} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/conways"}`}
                        component={conway} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/coulombs"}`}
                        component={coulombs} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/sierra"}`}
                        component={sierra} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/opengl"}`}
                        component={opengl} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/tanks"}`}
                        component={tanks} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/loaders_updated"}`}
                        component={loaders2} />

                    <Route path={`${process.env.PUBLIC_URL + "/about"}`}
                        component={AboutPage} />

                    <Route exact path={`${process.env.PUBLIC_URL + "/"}`}
                        component={PortfolioGridThreeColumnPage}
                    />
                    <Route exact path={`${process.env.PUBLIC_URL + "/portfolio"}`}
                        component={PortfolioGridThreeColumnPage}
                    />

                    <Route exact component={ErrorPage} />
                </Switch>
            </Router>
        </Fragment>
    );
};

export default App;