import React from 'react';

import AboutMe from "../../container/AboutMe";

const ContentAboutPage = (fullWidth) => {
    return (
        <div className="section-wrap section section-padding bg-grey" style={{ "min-height": "100vh", "padding-top": "200px" }}>
                <AboutMe type={'page'} />
        </div>
    );
};

export default ContentAboutPage;